<template>
  <div class="consulting_service_container">
    <div class="breadcrumb_wrap">
      <div class="m_1200">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{type==1?'心理咨询师':'精神科顾问'}}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
	<img src="../assets/images/jsk.jpg" alt="" class="banner" v-if="type==2">
    <div class="consulting_service_inner m_1200">
		  <div class="search_box">
		    <span class="label">全部</span>
		    <div class="input_wrap">
		      <el-input placeholder="请输入搜索内容" v-model.trim="keyword" class="input-with-select">
		        <el-button slot="append" icon="el-icon-search" @click="getList">搜索</el-button>
		      </el-input>
		    </div>
		  </div>
		<template v-if="type==1">
		  <div class="search_form">
		    <div class="form_item">
		      <span class="label">领域:</span>
		      <ul class="list">
		        <li class="item" @click="handleChooseItem('field',item)" v-for="item in searchData.field" :key="item.id" :class="{ active: item.checked }">{{ item.label }}</li>
		      </ul>
		    </div>
		    <div class="form_item">
		      <span class="label">城市:</span>
		      <ul class="list">
		        <li class="item" @click="handleChooseItem('city',item)" v-for="item in searchData.city" :key="item.id" :class="{ active: item.checked }">{{ item.label }}</li>
		      </ul>
		    </div>
		    <div class="form_item">
		      <span class="label">价格:</span>
		      <div class="price_wrap">
		        <div class="number_label">{{ searchForm.price[0] }} - {{ searchForm.price[1] }}</div>
		        <el-slider class="el_slider" range v-model="searchForm.price" :max="1000" @change="getList"></el-slider>
		      </div>
		    </div>
		    <div class="form_item">
		      <span class="label">针对群体(可多选):</span>
		      <ul class="list">
		        <li class="item" @click="handleChooseItem('group',item)" v-for="item in searchData.group" :key="item.id" :class="{ active: item.checked }">{{ item.label }}</li>
		      </ul>
		    </div>
		    <div class="form_item">
		      <span class="label">咨询方式:</span>
		      <ul class="list">
		        <li class="item" @click="handleChooseItem('type',item)" v-for="item in searchData.type" :key="item.id" :class="{ active: item.checked }">{{ item.label }}</li>
		      </ul>
		    </div>
		    <div class="form_item">
		      <span class="label">咨询师性别:</span>
		      <ul class="list">
		        <li class="item" @click="handleChooseItem('sex',item)" v-for="item in searchData.sex" :key="item.id" :class="{ active: item.checked }">{{ item.label }}</li>
		      </ul>
		    </div>
		  </div>
	  </template>
      <div class="list_wrapper">
        <router-link :to="'/doctorDetail?id=' + item.id" class="list_item" v-for="(item,index) in listData" :key="index">
          <img :src="item.avatar" alt="" class="photo">
          <div class="right">
            <div class="bar">
              <span class="name">{{item.name}}</span>
              <span class="position">{{item.city}}</span>
            </div>
            <p class="content">{{item.experience}}</p>
            <div class="tags">
              <span class="item" v-for="(itema,indexa) in item.direction" :key="indexa">{{itema}}</span>
            </div>
            <div class="price">{{item.price}}元/次</div>
          </div>
        </router-link>
		<div class="page_box m_1200">
		  <el-pagination
		    @size-change="handleSizeChange"
		    @current-change="handleCurrentChange"
		    :page-sizes="[10, 20, 30, 50, 100]"
		    :current-page="pageIndex"
		    :page-size="pageSize"
		    :background='true'
		    layout="total, sizes, prev, pager, next, jumper"
		    :total="total"
		    >
		  </el-pagination>
		</div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data () {
    return {
      keyword: '',//搜索关键词
      searchData: {//搜索参数数据
        field: [//领域
          { id: 0, label: '不限', checked: true },
          { id: 1, label: '情绪困扰', checked: false },
          { id: 2, label: '婚姻恋爱', checked: false },
          { id: 3, label: '人际关系', checked: false },
          { id: 4, label: '家庭困扰', checked: false },
          { id: 5, label: '个人成长', checked: false },
          { id: 6, label: '学业职场', checked: false },
          { id: 7, label: '亲子教育', checked: false },
          { id: 8, label: '心理健康', checked: false },
          { id: 9, label: '心理健康1', checked: false },
          { id: 10, label: '心理健康2', checked: false },
          { id: 11, label: '心理健康3', checked: false },
        ],
        city: [//城市
          { id: 0, label: '不限', checked: true },
          { id: 1, label: '北京市', checked: false },
          { id: 2, label: '成都市', checked: false },
          { id: 3, label: '广州市', checked: false },
          { id: 4, label: '杭州市', checked: false },
          { id: 5, label: '南京市', checked: false },
          { id: 6, label: '上海市', checked: false },
          { id: 7, label: '深圳市', checked: false }
        ],
        group: [//针对群体
          { id: 0, label: '不限', checked: true },
          { id: 1, label: '幼儿-儿童', checked: false },
          { id: 2, label: '青少年', checked: false },
          { id: 3, label: '伴侣', checked: false },
          { id: 4, label: '家庭', checked: false },
          { id: 5, label: '成年人', checked: false },
          { id: 6, label: '老年人', checked: false },
          { id: 7, label: '孕产妇', checked: false },
          { id: 8, label: '留学生', checked: false },
          { id: 9, label: '性少数人群', checked: false }
        ],
        type: [//咨询方式
          { id: 0, label: '不限', checked: true },
        ],
        sex: [//咨询性别
          { id: 0, label: '不限', checked: true },
          { id: 1, label: '男性咨询师', checked: false },
          { id: 2, label: '女性咨询师', checked: false }
        ]
      },
      searchForm: {//搜索参数
        field: 0,//领域
        city: 0,//城市
        price: [0, 1000],//价格
        group: 0,//针对群体
        type: 0,//咨询方式
        type: 0//咨询性别
      },
	  pageSize:10,
	  pageIndex:1,
	  keyword:'',
	  listData:[],
	  total:0,
	  type:1
    }
  },
  created () {
	  this.type=this.$route.query.type
	  this.cateList()
	  if(this.$route.query.val){
		var data={
		     city:0,
			  group:0,
			  price:[0,1000],
			  type:0,
			  label:this.$route.query.val
		  }
		  this.handleChooseItem('field',data)
	  }else{
		  this.getList()
	  }
  },
  methods: {
	cateList(){
		this.$http({
		  method: 'POST',
		  url: '/Other/CateLists',
		  data: {}
		}).then((res)=>{
			var fieldArr=[{ id: 0, label: '不限', checked: true }]
			res.data.direction.forEach((item,index)=>{
				fieldArr.push({id:index+1,label:item,checked:false})
			})
			var cityArr=[{ id: 0, label: '不限', checked: true }]
			res.data.city.forEach((item,index)=>{
				cityArr.push({id:index+1,label:item,checked:false})
			})
			var peopleArr=[{ id: 0, label: '不限', checked: true }]
			res.data.people.forEach((item,index)=>{
				peopleArr.push({id:index+1,label:item,checked:false})
			})
			var typeArr=[{ id: 0, label: '不限', checked: true }]
			res.data.mode.forEach((item,index)=>{
				typeArr.push({id:index+1,label:item,checked:false})
			})
			this.searchData.field=fieldArr
			this.searchData.city=cityArr
			this.searchData.group=peopleArr
			this.searchData.type=typeArr
		})
	},
	getList(){
		var params={
			pageSize:this.pageSize,pageIndex:this.pageIndex,
			direction:this.searchForm.field?this.searchForm.field:'',
			city:this.searchForm.city?this.searchForm.city:'',
			min_price:this.searchForm.price[0],
			max_price:this.searchForm.price[1],
			people:this.searchForm.group?this.searchForm.group:'',
			mode:this.searchForm.type?this.searchForm.type:'',
			sex:this.searchForm.sex=='不限' || this.searchForm.sex==undefined?'':(this.searchForm.sex=='男性咨询师'?1:2),
			keyword:this.keyword,
			cate:this.type
		}
		this.$http({
		  method: 'POST',
		  url: '/Other/getConsultant',
		  data: params
		}).then((res)=>{
			res.data.forEach(v=>{
				v.direction=v.direction.split(',')
			})
			this.listData=res.data
			this.total=res.count
		})
	},
	//页数发生改变
	handleSizeChange(val) {
	  this.pageSize = val
	  this.getList()
	},
	//页码发生改变
	handleCurrentChange(val) {
	  this.pageIndex = val
	  this.getList()
	},
    //处理选中搜索参数
    handleChooseItem (name,data) {
		console.log(this.searchData[name],111)
      for(let item of this.searchData[name]) {
        item.checked = false
      }
      data.checked = true
	  console.log( this.searchForm[name],99)
      this.searchForm[name] = data.label
	  this.getList()
    }
  },
}
</script>

<style lang="scss">
.consulting_service_container {
  padding-bottom: 42px;
  background-color: #ffffff;
  .banner{
	  width: 100%;
  }
  .breadcrumb_wrap {
    padding: 32px 0;
    background-color: #f5f5f5;
  }
  .consulting_service_inner {
    .search_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      padding: 30px 0;
      .label {
        padding-bottom: 6px;
        font-size: 16px;
        font-weight: bold;
        border-bottom: 3px solid #E51418;
      }
      .el-input-group__append {
        background-color: #E51418;
        color: #ffffff;
        border-color: #E51418;
      }
      .input_wrap {
        width: 360px;
      }
    }
    .search_form {
      padding: 12px 0;
      font-size: 16px;
      border: 1px solid #E8E8E8;
      .form_item {
        display: flex;
        padding: 0 15px;
        &+.form_item {
          padding-top: 15px;
          border-top: 1px solid #E8E8E8;
        }
        .label {
          height: 28px;
          line-height: 28px;
          margin-right: 22px;
        }
        
        .price_wrap {
          flex: 1;
          display: flex;
          .number_label {
            position: relative;
            top: 7px;
            margin-right: 24px;
          }
          .el_slider {
            flex: 1;
            position: relative;
            top: -5px;
            margin-bottom: 6px;
          }
          .el-slider__button {
            border-color: #E51418;
          }
          .el-slider__bar {
            background-color: #E51418;
          }
        }
        .list {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          .item {
            padding: 0 15px;
            margin-bottom: 12px;
            margin-right: 24px;
            cursor: pointer;
            &:hover,
            &.active {
              
              border-radius: 3px;
              color: #ffffff;
              background-color:#E51418;
            }
          }
        }
      }
    }
    .list_wrapper {
      .list_item {
        display: flex;
        padding: 32px 0;
        &+.list_item {
          border-top: 1px solid #E8E8E8;
        }
        .photo {
          width: 170px;
          height: 170px;
          margin-right: 20px;
        }
        .right {
          display: flex;
          flex-direction: column;
          width: 555px;
          .bar {
            display: flex;
            align-items: center;
            justify-content: space-between;
			color: #333;
            .name {
              font-size: 20px;
              font-weight: bold;
            }
            .position {
              height: 16px;
              padding-left: 20px;
              line-height: 16px;
              color: #999999;
              font-size: 14px;
              background: url('../assets/images/consultingService2.png') no-repeat left center / 16px 16px;
            }
          }
          .content {
            margin: 10px 0 16px;
            color: #999999;
            font-size: 13px;
            line-height: 1.5;
            text-align: justify;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          .tags {
            display: flex;
            font-size: 16px;
			color: #999;
            .item {
              margin-right: 12px;
            }
          }
          .price {
            margin-top: auto;
            color: #E51418;
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .doctor_detail_container {
    padding-bottom: 24px;
    background-color: #ffffff;
    .breadcrumb_wrap {
      padding: 15px;
      background-color: #f5f5f5;
    }
  }
  .consulting_service_container {
    padding-bottom: 24px;
    background-color: #ffffff;
    .breadcrumb_wrap {
      padding: 15px;
      background-color: #f5f5f5;
    }
    .consulting_service_inner {
      box-sizing: border-box;
      padding: 15px;
      .search_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
        padding: 15px 0;
        .label {
          display: none;
          padding-bottom: 6px;
          font-size: 12px;
          font-weight: bold;
          border-bottom: 3px solid #E51418;
        }
        .el-input-group__append {
          background-color: #E51418;
          color: #ffffff;
          border-color: #E51418;
        }
        .input_wrap {
          width: 360px;
        }
      }
      .search_form {
        padding: 12px 0;
        font-size: 12px;
        border: 1px solid #E8E8E8;
        .form_item {
          display: flex;
          padding: 0 15px;
          &+.form_item {
            padding-top: 15px;
            border-top: 1px solid #E8E8E8;
          }
          .label {
            height: 20px;
            line-height: 20px;
            margin-right: 12px;
          }
          
          .price_wrap {
            flex: 1;
            display: flex;
            .number_label {
              position: relative;
              top: 5px;
              margin-right: 24px;
            }
            .el_slider {
              flex: 1;
              position: relative;
              top: -9px;
              margin-bottom: 6px;
            }
            .el-slider__button {
              border-color: #E51418;
            }
            .el-slider__bar {
              background-color: #E51418;
            }
          }
          .list {
            .item {
              padding: 0 15px;
              margin-bottom: 6px;
              line-height: 20px;
              margin-right: 0px;
              cursor: pointer;
              &:hover,
              &.active {
                border-radius: 3px;
                color: #ffffff;
                background-color:#E51418;
              }
            }
          }
        }
      }
      .list_wrapper {
        .list_item {
          display: flex;
          padding: 15px 0;
          &+.list_item {
            border-top: 1px solid #E8E8E8;
          }
          .photo {
            width: 80px;
            height: 80px;
            margin-right: 10px;
          }
          .right {
            display: flex;
            flex-direction: column;
            flex: 1;
            .bar {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .name {
                font-size: 14px;
                font-weight: bold;
              }
              .position {
                height: 16px;
                padding-left: 20px;
                line-height: 16px;
                color: #999999;
                font-size: 12px;
                background: url('../assets/images/consultingService2.png') no-repeat left center / 16px 16px;
              }
            }
            .content {
              margin: 5px 0 8px;
              color: #999999;
              font-size: 12px;
              line-height: 1.2;
              text-align: justify;
              display: -webkit-box;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
            .tags {
              display: flex;
              flex-wrap: wrap;
              font-size: 16px;
              .item {
                margin-bottom: 8px;
                margin-right: 12px;
                white-space: nowrap;
              }
            }
            .price {
              margin-top: 5px;
              margin-top: auto;
              color: #E51418;
              font-size: 16px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>